import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/jerry/Code/Jerrytron/jerrytron-com/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {/* ![jerry belich](../images/home/home-jerry-belich.jpg "Jerry Belich") */}
    <h4>{`Hi! I'm Jerry Belich. `}<a parentName="h4" {...{
        "href": "/projects/"
      }}>{`Welcome to my portfolio!`}</a></h4>
    <p>{`I'm currently a senior content / narrative designer at `}<a parentName="p" {...{
        "href": "https://squanchgames.com/2021/04/16/meet-squanch-presents-drew-jerry/"
      }}>{`Squanch Games`}</a>{`.`}</p>
    {/* <props.imgs.Image1 align="left" width="40%" /> */}
    <p>{`I specialize at breathing life into extraordinary creative and technologically based experiences. Recently this has meant providing game and narrative design services as well as hardware and software implementation for location based experiences, while also exhibiting bespoke alt ctrl games and installations.`}</p>
    <p>{`I have decades of experience spanning sectors, including industry leaders in medical and defense technology, award-winning digital agencies, museums, academia, and of course games. The common theme? Happier, more effective teams and finished projects. Whether in a leadership, creative, or technical role, I love designing systems, tools, and processes to support a healthy team and project.`}</p>
    <p>{`You may have already enjoyed some of my work playing `}<a parentName="p" {...{
        "href": "/projects/choosatron/"
      }}>{`Choosatron`}</a>{` at a museum, `}<a parentName="p" {...{
        "href": "/projects/high-on-life/"
      }}>{`High on Life & DLC`}</a>{` on Steam and console, Owlchemy’s `}<a parentName="p" {...{
        "href": "/projects/dyscourse/"
      }}>{`Dyscourse`}</a>{` on Steam, the `}<a parentName="p" {...{
        "href": "/projects/utopia-room/"
      }}>{`Utopia escape room`}</a>{` in Minneapolis, or even the entryway of the `}<a parentName="p" {...{
        "href": "/projects/cosmo-casino/"
      }}>{`Cosmopolitan Casino`}</a>{` in Las Vegas.`}</p>
    <p>{`Take a look at my `}<a parentName="p" {...{
        "href": "/files/resume-jerry_belich.pdf"
      }}><strong parentName="a">{`resume`}</strong></a>{` or for detailed exhibition, speaking, etc. info look at my `}<a parentName="p" {...{
        "href": "/files/artist_cv-jerry_belich.pdf"
      }}><strong parentName="a">{`artist cv`}</strong></a>{`. Some of my services include:`}</p>
    <ul>
      <li parentName="ul">{`narrative / content / game design`}</li>
      <li parentName="ul">{`technical producing`}</li>
      <li parentName="ul">{`escape room / location based game design`}</li>
      <li parentName="ul">{`interactive installations`}</li>
      <li parentName="ul">{`digital and hardware prototyping`}</li>
      <li parentName="ul">{`alt ctrls / custom interfaces / interaction design`}</li>
    </ul>
    <p>{`Whatever your audience, I can help you design and implement experiences that will leave them delighted.`}</p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      